import React from 'react';
import { Routes } from './Routes.js';

function App() {
  return (
    <Routes/>
  );
}

export default App;
